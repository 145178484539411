import React from "react";
import { Link } from "react-router-dom";

const Career = () => {
  return (
    <div className="bg-gray-900 py-16 px-4 text-white">
      <div className="max-w-7xl mx-auto">
        {/* Page Header */}
        <header className="text-center mb-12">
          <br></br>
          <h1 className="text-purple-700 text-6xl font-bold">
            MetaMind Kariyer
          </h1>
          <p className="text-lg mt-4">
            Yaratıcı ve tutkulu bir ekip kuruyoruz. Gelin, bir parçası olun!
          </p>
        </header>

        {/* Şirket Kültürü */}
        <section className="mb-16">
          <h2 className="text-3xl font-semibold mb-6">Şirket Kültürümüz</h2>
          <p className="text-lg mb-6">
            MetaMind Evolution olarak inovasyon, yenilik ve müşteri memnuniyeti
            odaklı bir çalışma kültürüne sahibiz. Ekip üyelerimiz, sürekli
            öğrenmeye, yaratıcı çözümler üretmeye ve birlikte başarmaya
            odaklanmaktadır. Çeşitliliği ve işbirliğini önemsiyor, her bir
            bireyi değerli kılmaya çalışıyoruz.
          </p>
          <p className="text-lg">
            Ekibimize katılarak kariyerinizde önemli bir adım atabilir ve
            büyüyen bir şirketin parçası olabilirsiniz. Her bireyin katkısının
            çok değerli olduğu bir çalışma ortamında sizin gibi tutkulu
            profesyonelleri bekliyoruz.
          </p>
        </section>

        {/* Açık Pozisyonlar */}
        <section className="mb-16">
          <h2 className="text-3xl font-semibold mb-6">Açık Pozisyonlar</h2>
          <div className="grid md:grid-cols-2 gap-8">
            {/* Pozisyon 1 */}
            <div className="bg-gray-800 p-6 rounded-lg shadow-xl hover:shadow-2xl transition">
              <h3 className="text-2xl font-bold">Yazılım Geliştirici</h3>
              <p className="text-gray-400 mt-2">
                MetaMind Evolution olarak yenilikçi projeler geliştirecek, dinamik
                bir ekip içinde yer alacak yazılım geliştiriciler arıyoruz. Yaratıcı
                ve çözüm odaklı bir bakış açısına sahip olmalısınız.
              </p>
              <Link
                to="/job-details/software-developer"
                className="text-blue-500 mt-4 inline-block hover:underline"
              >
                Detayları Görüntüle
              </Link>
            </div>

            {/* Pozisyon 2 */}
            <div className="bg-gray-800 p-6 rounded-lg shadow-xl hover:shadow-2xl transition">
              <h3 className="text-2xl font-bold">Pazarlama Yöneticisi</h3>
              <p className="text-gray-400 mt-2">
                MetaMind Evolution'un büyüyen pazarlama ekibine katılacak, dijital
                pazarlama stratejilerini yönetecek ve markamızı tanıtacak bir pazarlama
                yöneticisi arıyoruz. Yaratıcı ve stratejik düşünce yapısına sahip
                olmanız beklenmektedir.
              </p>
              <Link
                to="/job-details/marketing-manager"
                className="text-blue-500 mt-4 inline-block hover:underline"
              >
                Detayları Görüntüle
              </Link>
            </div>
          </div>
        </section>

        {/* İşe Alım Süreci - Roadmap */}
        <section className="mb-16">
          <h2 className="text-3xl font-semibold mb-6">İşe Alım Sürecimiz</h2>
          <div className="flex flex-col md:flex-row md:space-x-8 space-y-8 md:space-y-0">
            <div className="bg-gray-800 p-6 rounded-lg shadow-xl w-full text-center">
              <h3 className="text-2xl font-bold mb-4">Adım 1: Başvuru</h3>
              <p className="text-gray-400">
                İlk olarak, açık pozisyonlarımıza başvurunuzu göndermenizi bekliyoruz.
                Başvurunuzda, deneyimleriniz ve yeteneklerinizle nasıl katkı sağlayabileceğiniz
                hakkında bilgi verin.
              </p>
            </div>

            <div className="bg-gray-800 p-6 rounded-lg shadow-xl w-full text-center">
              <h3 className="text-2xl font-bold mb-4">Adım 2: Görüşme</h3>
              <p className="text-gray-400">
                Başvurunuz başarılı olduğunda, sizi bir mülakata davet edeceğiz. Bu aşama,
                sizinle daha yakından tanışmak ve yeteneklerinizi daha iyi anlamak için
                fırsat yaratacaktır.
              </p>
            </div>

            <div className="bg-gray-800 p-6 rounded-lg shadow-xl w-full text-center">
              <h3 className="text-2xl font-bold mb-4">Adım 3: Teklif</h3>
              <p className="text-gray-400">
                Eğer her şey yolunda giderse, size iş teklifimizi sunacağız. Ardından
                birlikte çalışma sürecine başlayacağız.
              </p>
            </div>
          </div>
        </section>

        {/* Başvuru Yönlendirmesi */}
        <section className="text-center">
          <p className="text-lg mb-6">
            MetaMind Evolution ailesine katılmaya hazır mısınız? Hemen başvurunuzu
            yapın!
          </p>
          <Link
            to="/apply"
            className="bg-blue-600 text-white px-6 py-3 rounded-md font-medium hover:bg-blue-700 transition"
          >
            Başvurunuzu Yapın
          </Link>
        </section>
      </div>
    </div>
  );
};

export default Career;
