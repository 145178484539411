import React from 'react';
import CafeWareLogo from '../assets/coffee-cup.png';

const Analytics = () => {
  return (
    <div className="w-full bg-white py-16 px-4">
      <div className="max-w-[1240px] mx-auto grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
        {/* Image */}
        <img
          className="w-[250px] sm:w-[300px] mx-auto md:mx-0"
          src={CafeWareLogo}
          alt="CafeWare Logo"
        />
        {/* Text Content */}
        <div className="flex flex-col justify-center space-y-6">
          <p className="text-[#df00c5] font-bold text-center sm:text-left">Yapay Zeka ile Güncellenmiş Restoran Otomasyon Yazılımı</p>
          <h1 className="text-purple-700 md:text-6xl sm:text-3xl text-2xl font-bold text-center sm:text-left">CafeWare</h1>
          <p className="text-gray-700 text-base text-justify">
            Modern restoran ve kafe işletmeleri için tasarlanan CafeWare, işletmenizin yönetim ve satış süreçlerini yeniden tanımlayan yenilikçi bir deneyim sunar. CafeWare, sektörünüzdeki rekabet avantajınızı artırmanıza yardımcı olmak amacıyla bir dizi gelişmiş özellik ile donatılmıştır.
          </p>
          <button className="bg-[#df00c5] text-white w-[200px] rounded-md font-medium py-3 mx-auto md:mx-0 transition-all hover:bg-[#b400b3]">
            İletişime Geçin
          </button>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
