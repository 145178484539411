import React from 'react';
import { FaRobot, FaChartLine, FaRegLightbulb, FaClipboardList, FaUserCircle, FaPuzzlePiece } from 'react-icons/fa';

const Cards = () => {
  return (
    <div className="container mx-auto px-4 py-12">
      <h2 className="text-4xl font-bold text-center mb-12 text-white">Neden CafeWare?</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
        {/* Gelişmiş Otomasyon */}
        <div className="bg-white p-8 rounded-xl shadow-xl transform transition duration-500 hover:scale-105 hover:shadow-2xl">
          <div className="text-red-600 mb-6 text-center">
            <FaRobot className="w-16 h-16 mx-auto" />
          </div>
          <h3 className="text-black text-2xl font-semibold mb-4 text-center">Gelişmiş Otomasyon</h3>
          <p className="text-black text-base text-justify">
            CafeWare, sektördeki muadillerine kıyasla daha fazla otomasyon imkânı sunar. Sipariş alma, ödeme işlemleri, masa yönetimi gibi rutin operasyonlarınızı kolaylaştırarak, işletmenizin verimliliğini üst düzeye taşır. Böylece, personelinizin daha önemli görevlere odaklanmasını sağlar.
          </p>
        </div>

        {/* Yapay Zeka Destekli Stok Takibi */}
        <div className="bg-white p-8 rounded-xl shadow-xl transform transition duration-500 hover:scale-105 hover:shadow-2xl">
          <div className="text-yellow-700 mb-6 text-center">
            <FaChartLine className="w-16 h-16 mx-auto" />
          </div>
          <h3 className="text-black text-2xl font-semibold mb-4 text-center">Yapay Zeka Destekli Stok Takibi</h3>
          <p className="text-black text-base text-justify">
            CafeWare’in Yapay Zeka destekli stok takibi, malzemelerinizin güncel durumu hakkında anında bilgi sağlar ve stok eksikliklerini önceden tahmin ederek sipariş sürecinizi optimize eder.
          </p>
        </div>

        {/* Yeni Ürün Öneri Yazılımı */}
        <div className="bg-white p-8 rounded-xl shadow-xl transform transition duration-500 hover:scale-105 hover:shadow-2xl">
          <div className="text-indigo-700 mb-6 text-center">
            <FaRegLightbulb className="w-16 h-16 mx-auto" />
          </div>
          <h3 className="text-black text-2xl font-semibold mb-4 text-center">Yeni Ürün Öneri Yazılımı</h3>
          <p className="text-black text-base text-justify">
            CafeWare, satış verilerinizi analiz ederek müşterilerinizin ilgisini çekebilecek yeni ürünler önerir. Bu özellik, menünüzü sürekli güncel tutmanıza ve müşteri memnuniyetini artırmanıza yardımcı olur.
          </p>
        </div>

        {/* Gelişmiş Raporlama */}
        <div className="bg-white p-8 rounded-xl shadow-xl transform transition duration-500 hover:scale-105 hover:shadow-2xl">
          <div className="text-green-700 mb-6 text-center">
            <FaClipboardList className="w-16 h-16 mx-auto" />
          </div>
          <h3 className="text-black text-2xl font-semibold mb-4 text-center">Gelişmiş Raporlama</h3>
          <p className="text-black text-base text-justify">
            CafeWare'in gelişmiş raporlama araçları, işletmenizin finansal performansını ayrıntılı bir şekilde analiz eder. Satış trendleri, müşteri tercihleri, ve kârlılık analizleri gibi kritik verileri görselleştirerek, stratejik kararlar almanıza olanak tanır.
          </p>
        </div>

        {/* Kullanıcı Dostu Arayüz */}
        <div className="bg-white p-8 rounded-xl shadow-xl transform transition duration-500 hover:scale-105 hover:shadow-2xl">
          <div className="text-orange-600 mb-6 text-center">
            <FaUserCircle className="w-16 h-16 mx-auto" />
          </div>
          <h3 className="text-black text-2xl font-semibold mb-4 text-center">Kullanıcı Dostu Arayüz</h3>
          <p className="text-black text-base text-justify">
            CafeWare, kullanıcı dostu ve sezgisel bir arayüz ile tasarlanmıştır. Personelinizin hızlı bir şekilde adapte olabileceği bu arayüz, günlük operasyonları kolaylaştırır ve eğitim sürelerini minimuma indirir.
          </p>
        </div>

        {/* Entegre Çözümler */}
        <div className="bg-white p-8 rounded-xl shadow-xl transform transition duration-500 hover:scale-105 hover:shadow-2xl">
          <div className="text-purple-600 mb-6 text-center">
            <FaPuzzlePiece className="w-16 h-16 mx-auto" />
          </div>
          <h3 className="text-black text-2xl font-semibold mb-4 text-center">Entegre Çözümler</h3>
          <p className="text-black text-base text-justify">
            CafeWare, çeşitli ödeme sistemleri ve üçüncü parti uygulamalar ile entegrasyon imkanı sunar. Bu sayede, tüm iş süreçlerinizi tek bir platform üzerinden yönetebilirsiniz.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Cards;
