import React from "react";
import { Link } from "react-router-dom";

const Blogpage = () => {
    return (
        <div className="bg-gray-900 py-16 px-4 text-white">
            <div className="max-w-7xl mx-auto">
                {/* Page Header */}
                <header className="text-center mb-12">
                    <h1 className="text-4xl font-bold">
                        MetaMind Blog
                    </h1>
                    <p className="text-lg mt-4">
                        Şirket içi haberler, etkinlikler, yenilikler ve daha fazlası burada!
                    </p>
                </header>

                {/* Blog Yazıları */}
                <section className="grid md:grid-cols-3 gap-12">
                    {/* Blog Yazısı 1 */}
                    <div className="bg-gray-800 p-6 rounded-lg shadow-xl hover:shadow-2xl transition">
                        <h2 className="text-2xl font-bold mb-4">MetaMind 2024 Yılına Merhaba Dedi!</h2>
                        <p className="text-gray-400 mb-4">
                            2024 yılına başlarken, ekibimiz için heyecan verici birçok yeni proje ve hedeflerimiz var.
                            Yıl boyunca gerçekleştireceğimiz etkinlikler ve şirket içi gelişmeler hakkında daha fazla bilgi edinmek için yazımıza göz atın.
                        </p>
                        <Link to="/blog/meta-2024-launch" className="text-blue-500 hover:underline">
                            Devamını Oku
                        </Link>
                    </div>

                    {/* Blog Yazısı 2 */}
                    <div className="bg-gray-800 p-6 rounded-lg shadow-xl hover:shadow-2xl transition">
                        <h2 className="text-2xl font-bold mb-4">Yeni Eğitim Programlarımız Başladı!</h2>
                        <p className="text-gray-400 mb-4">
                            Şirket içi eğitim programlarımızla çalışanlarımızın gelişimine katkıda bulunmayı amaçlıyoruz.
                            Yeni başlayan eğitim serilerimiz ve içerikleri hakkında daha fazla bilgiye yazımızdan ulaşabilirsiniz.
                        </p>
                        <Link to="/blog/new-training-program" className="text-blue-500 hover:underline">
                            Devamını Oku
                        </Link>
                    </div>

                    {/* Blog Yazısı 3 */}
                    <div className="bg-gray-800 p-6 rounded-lg shadow-xl hover:shadow-2xl transition">
                        <h2 className="text-2xl font-bold mb-4">2023 Yılında Gerçekleştirdiğimiz Etkinlikler</h2>
                        <p className="text-gray-400 mb-4">
                            Geçtiğimiz yıl MetaMind Evolution olarak katıldığımız etkinlikler, konferanslar ve sektör toplantıları hakkında detaylı bir değerlendirme yaptık.
                            Bu yazımızda, 2023 yılındaki en önemli anlarımıza göz atabilirsiniz.
                        </p>
                        <Link to="/blog/2023-events" className="text-blue-500 hover:underline">
                            Devamını Oku
                        </Link>
                    </div>
                </section>

                {/* Daha Fazla Blog Yazısı */}
                <section className="text-center mt-12">
                    <Link to="/all-blogs" className="bg-blue-600 text-white px-6 py-3 rounded-md font-medium hover:bg-blue-700 transition">
                        Tüm Yazılara Göz Atın
                    </Link>
                </section>
            </div>
        </div>
    );
};

export default Blogpage;
