import React from 'react';

const Hero = () => {
  return (
    <div className='text-white'>
      <div className='max-w-[800px] mt-[-96px] w-full mx-auto text-center flex flex-col justify-center' style={{ height: '400px' }}>
        <p style={{ fontSize: '25px', marginTop: '130px' }} className='text-[#3bff69] font-bold p-2'>
          Yeni Nesil Restoran Otomasyonu
        </p>
        <h1 className='md:text-7xl sm:text-6xl text-4xl font-bold md:py-6'>
          CafeWare
        </h1>
      </div>
    </div>
  );
};

export default Hero;
