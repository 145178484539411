import React, { useState } from 'react';
import CafeWareMain from '../assets/cafeware_main.jpeg';
import DynamicMenu from '../assets/dynamic-menu.jpeg';
import GraphicsPhoto from '../assets/graphic.png'

// Fotoğraflar ve açıklamaları
const photos = [
    { src: CafeWareMain, alt: 'Fotoğraf 1', desc: 'CafeWare\'in ana ekranı.' },
    { src: DynamicMenu, alt: 'Fotoğraf 2', desc: 'Dinamik menü ekranı.' },
    { src: GraphicsPhoto, alt: 'Fotoğraf 3', desc: 'Satış grafikleri.' },
];

const Gallery = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handlePrevClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + photos.length) % photos.length);
    };

    const handleNextClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % photos.length);
    };

    return (
        <div className="bg-white py-16">
            <div className="container mx-auto text-center px-4">
                <h2 className="text-3xl font-bold mb-8 text-gray-800">CafeWare - Uygulama Görüntüleri</h2>

                {/* Carousel Container */}
                <div className="carousel-container relative overflow-hidden">
                    {/* Carousel Slide */}
                    <div
                        className="carousel-slide flex transition-transform duration-500 ease-in-out"
                        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                    >
                        {photos.map((photo, index) => (
                            <div className="carousel-item min-w-full" key={index}>
                                <img
                                    src={photo.src}
                                    alt={photo.alt}
                                    className="w-full h-[500px] sm:h-[600px] object-cover rounded-lg shadow-md"
                                />
                            </div>
                        ))}
                    </div>

                    {/* Previous Button */}
                    <button
                        aria-label="Previous Image"
                        className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-white text-gray-900 p-3 rounded-full shadow-lg hover:bg-gray-200 transition"
                        onClick={handlePrevClick}
                    >
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path>
                        </svg>
                    </button>

                    {/* Next Button */}
                    <button
                        aria-label="Next Image"
                        className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-white text-gray-900 p-3 rounded-full shadow-lg hover:bg-gray-200 transition"
                        onClick={handleNextClick}
                    >
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
                        </svg>
                    </button>
                </div>

                {/* Description Text */}
                <div className="mt-6">
                    <p className="text-xl text-gray-700">{photos[currentIndex].desc}</p>
                </div>
            </div>
        </div>
    );
};

export default Gallery;
