import React from 'react';
import { AiOutlineStock } from "react-icons/ai";
import { PiCoffeeLight } from "react-icons/pi";
import { BsRobot } from "react-icons/bs";
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const Cards = () => {
  return (
    <div className='w-full py-[10rem] px-4 bg-white'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8 text-black'>
        <div className='w-full shadow-xl flex flex-col p-8 my-4 rounded-lg hover:scale-105 duration-300  text-white min-h-[450px] bg-gradient-to-r from-purple-800 via-black to-blue-900'>
          <div className='flex justify-center mb-6'>
            <AiOutlineStock style={{ fontSize: '105px' }} className='text-[#ffffff]' />
          </div>
          <h2 className='text-3xl font-bold text-center py-8'>StockyWave</h2>
          <div className='text-center font-medium'>
            <p className='py-2 border-b mx-8 mt-8'>Stok Takibi</p>
            <p className='py-2 border-b mx-8'>Yapay Zeka Destekli Stok Analizi</p>
            <p className='py-2 border-b mx-8'>Chatbot ve 7/24 Çevrimiçi Destek</p>
          </div>
          <Link to="/chatbot" className='bg-[#ff11d3] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3 text-center text-white'>
            Daha Fazla Bilgi Alın
          </Link>
        </div>
        <div className='w-full shadow-xl flex flex-col p-8 my-4 rounded-lg hover:scale-105 duration-300 text-white min-h-[450px] bg-gradient-to-r from-purple-800 via-black to-blue-900'>
          <div className='flex justify-center mb-6'>
            <PiCoffeeLight style={{ fontSize: '105px' }} className='text-[#ffffff]' />
          </div>
          <h2 className='text-4xl font-bold text-center py-8'>CafeWare</h2>
          <div className='text-center font-medium'>
            <p className='py-2 border-b mx-8 mt-8'>Restoran Otomasyonu</p>
            <p className='py-2 border-b mx-8'>Yapay Zeka Destekli Satış Analizi</p>
            <p className='py-2 border-b mx-8'>Chatbot ve 7/24 Çevrimiçi Destek</p>
          </div>
          <Link to="/cafeware" className='bg-[#ff11d3] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3 text-center text-white'>
            Daha Fazla Bilgi Alın
          </Link>
        </div>
        <div className='w-full shadow-xl flex flex-col p-8 my-4 rounded-lg hover:scale-105 duration-300 text-white min-h-[450px] bg-gradient-to-r from-purple-800 via-black to-blue-900'>
          <div className='flex justify-center mb-6'>
            <BsRobot style={{ fontSize: '105px' }} className='text-[#ffffff]' />
          </div>
          <h2 className='text-3xl font-bold text-center py-8'>Chatbot</h2>
          <div className='text-center font-medium'>
            <p className='py-2 border-b mx-8 mt-8'>Kişiselleştirilmiş Chatbot</p>
            <p className='py-2 border-b mx-8'>GPT Desteği</p>
            <p className='py-2 border-b mx-8'>7/24 Çevrimiçi Destek</p>
          </div>
          <Link to="/chatbot" className='bg-[#ff11d3] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3 text-center text-white'>
            Daha Fazla Bilgi Alın
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Cards;