import React from 'react';
import {
  FaInstagram,
  FaTwitterSquare,
  FaLinkedin,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="bg-black text-gray-300">
      <div className='max-w-[1240px] mx-auto py-12 px-4 grid lg:grid-cols-3 gap-8'>
        <div className='lg:col-span-1'>
          <h1 className="font-bold text-3xl bg-clip-text text-transparent bg-gradient-to-r from-purple-800 via-purple-500 to-blue-400">
            MetaMind Evolution
          </h1>
          <p className='py-3 text-sm'>
            Yeni Nesil Hizmetlerimiz ile Şirketinizi Bir Adım Öteye Taşıyın
          </p>
          <div className='flex space-x-4 mt-4'>
            <a href="https://www.instagram.com/metamindevolution?igsh=cmNsM3c1dmc3N3Vp&utm_source=qrL" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
              <FaInstagram size={28} className="text-white hover:opacity-75" />
            </a>
            <a href="https://x.com/behicoytunsnkl/" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
              <FaTwitterSquare size={28} className="text-white hover:opacity-75" />
            </a>
            <a href="https://www.linkedin.com/in/metamind-evolution-4904b22b8/" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
              <FaLinkedin size={28} className="text-white hover:opacity-75" />
            </a>
          </div>
        </div>
        <div className='lg:col-span-2 grid sm:grid-cols-3 gap-8'>
          <div>
            <h6 className='text-xl text-white mb-4'>Hizmetlerimiz</h6>
            <ul>
              <li className='py-1'>
                <Link to='/chatbot' className='hover:text-purple-400 transition duration-200'>CafeWare</Link>
              </li>
              <li className='py-1'>
                <Link to='/chatbot' className='hover:text-purple-400 transition duration-200'>StockyWave</Link>
              </li>
              <li className='py-1'>
                <Link to='/chatbot' className='hover:text-purple-400 transition duration-200'>Chatbot</Link>
              </li>
            </ul>
          </div>
          <div>
            <h6 className='text-xl text-white mb-4'>Şirket Hakkında</h6>
            <ul>
              <li className='py-1'>
                <Link to='/about' className='hover:text-purple-400 transition duration-200'>Biz Kimiz</Link>
              </li>
              <li className='py-1'>
                <Link to='/blogpage' className='hover:text-purple-400 transition duration-200'>Blog</Link>
              </li>
              <li className='py-1'>
                <Link to='/career' className='hover:text-purple-400 transition duration-200'>Kariyer</Link>
              </li>
            </ul>
          </div>
          <div>
            <h6 className='text-xl text-white mb-4'>Gizlilik</h6>
            <ul>
              <li className='py-1'>
                <Link to='/policy' className='hover:text-purple-400 transition duration-200'>Veri Toplama</Link>
              </li>
              <li className='py-1'>
                <Link to='/policy' className='hover:text-purple-400 transition duration-200'>Politikamız</Link>
              </li>
              <li className='py-1'>
                <Link to='/policy' className='hover:text-purple-400 transition duration-200'>Raporlarımız</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <footer className="bg-black bg-opacity-70 p-4 text-center text-gray-400">
        <p>&copy; 2024 MetaMind Evolution. Tüm hakları saklıdır.</p>
      </footer>
    </div>
  );
};

export default Footer;